<template>
  <div class="modal page page--modal" v-if="getCloseModalEnabled">
    <Header />
    <div class="page__content">
      <h1><Translate :translate="'sureWantToExit'" /></h1>
      <p><Translate :translate="'progressWillBeLost'" /></p>
    </div>
    <Footer>
      <div class="modal__footer">
        <button
          class="button--primary"
          @click="closeModalYes()"
          :style="`background: ${getConfig.cta_button_backcolor};
                   color: ${getConfig.cta_button_forecolor}`">
          <Translate :translate="'yesExit'" />
        </button>
        <button class="button--secondary" @click="closeModalNo()">
          <Translate :translate="'noContinue'" />
        </button>
      </div>
    </Footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Navigation from '@/mixins/navigate';
import analytics from '@/api/analytics';
import Header from './Header';
import Footer from './Footer';

export default {
  name: 'CloseModal',
  components: { Footer, Header },
  mixins: [Navigation],
  computed: {
    ...mapGetters(['getCloseModalEnabled', 'getConfig']),
  },
  methods: {
    closeModalYes() {
      // eslint-disable-next-line
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage('closeSession');
      } else {
        window.top.postMessage('closeSession', '*');
        // eslint-disable-next-line
        window.RolleeConnectFlutter && window.RolleeConnectFlutter.postMessage('closeSession');
      }

      analytics.dispatch({
        action: 'getSessionDuration',
        fromScreen: this.$route.name,
        toScreen: 'Close',
      });


      this.goBackTo().then(()=>{
        this.$store.commit('SET_CLOSE_MODAL_ENABLED', false);
        this.$store.commit('SET_COUNTRY_MODAL', false);
        this.$store.commit('SET_CODE_MODAL', false);
        this.$store.commit('SET_APPROVAL_MODAL', false);
      })
    },
    closeModalNo() {
      this.$store.commit('SET_CLOSE_MODAL_ENABLED', false);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 99;
  grid-template-rows: $header-height auto 140px;
  &__footer {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 12px;
  }
}
</style>
