<template>
  <div class="page page--actions">
    <Header :hasClose="true" :hasBack="true" @goBack="goBack" />
    <div class="login__source"
      :class="{
        'merged': getSelectedSource?.merged_logo,
        }">
        <div
          class="login__source-icon"
          :class="{
            'login__source--merged': getSelectedSource?.merged_logo,
          }">
          <img
            v-if="getSelectedSource.merged_logo"
            ref="sourceLogo"
            :src="getSelectedSource.merged_logo" />
          <img
            v-if="getAvatar(getSelectedSource) && !getSelectedSource.merged_logo"
            ref="sourceLogo"
            :src="getAvatar(getSelectedSource)" />
          <div
            v-if="!getAvatar(getSelectedSource) && !getSelectedSource.merged_logo"
            class="page__list-default" />
        </div>
        <div :dir="textDirection" class="login__source-title">
          <h2>
            <Translate :translate="'logInTo'" />
            <p v-html="getSelectedSource?.merged_name || getSelectedSource.name" />
          </h2>
        </div>
      </div>
    <div class="page__content">
      <ActionRequiredWarrning :title="'actionWarningBlockedTitle'" :text="blockedAccountTranslation"/>
    </div>
    <div class="page__footer-wrapper">
      <TermsOfUseLogin />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header';
import TermsOfUseLogin from '@/components/TermsOfUseLogin';
import ActionRequiredWarrning from '@/components/ActionRequiredWarning';
import Localization from '@/mixins/localization';

export default {
  name: 'Actions',
  components: {
    Header,
    TermsOfUseLogin,
    ActionRequiredWarrning
  },
  mixins: [Localization],
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters(['getConfig', 'getSelectedSource', 'translations']),
    blockedAccountTranslation(){
      return this.translations.actionWarningBlockedText.replace('{value}', this.convertMinutes(this.getSelectedSource?.block_account_config?.max_attempts_expiration))
    }
  },
  mounted() {
  },
  methods: {
    goBack() {
      this.$router.push({ name: 'Login', query: this.$route.query });
    },
    convertMinutes(minutes) {
    if (minutes < 60) {
        return `${minutes}m`;
    }

    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    return remainingMinutes === 0
        ? `${hours}h`
        : `${hours}h:${remainingMinutes}m`;
}
  },
};

</script>
<style lang="scss" scoped>
.page{
  grid-template-rows: 25px 94px 1fr 72px;
  align-content: flex-start;
  &__login {
    display: grid;
    grid-template-columns: 25px auto 1fr;
    align-content: flex-start;
    position: relative;
    &.rtl {
      textarea {
        padding-right: 50px;
      }

      & .login {
        &__form {
          &-title {
            h2 {
              text-align: right;
            }
          }
        }
      }
    }
  }

  &__content{
    display: flex;
    align-items: center;
    justify-items: center;
  }
  &__footer{
    &-wrapper{
      display: flex;
      align-items: flex-end;
    }
  }
}
.login{
  &__source{
      width: 100%;
    }
}
</style>
