<template>
  <div class="search" :class="{ rtl: isRtl, focused }">
    <SearchIcon class="search--icon" />
    <input
      :dir="textDirection"
      :placeholder="placeholder"
      type="text"
      ref="search"
      data-hj-allow
      class="data-hj-allow"
      v-model="searchValue"
      @focus="focused = true"
      @blur="focused = false"
      @input="emitVal()" />
    <CloseFilledIcon
      v-if="searchValue.length > 0"
      class="search--remove"
      @click="onRemove" />
    <div class="search-line" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Localization from '@/mixins/localization';
import SearchIcon from './icons/SearchIcon';
import CloseFilledIcon from './icons/CloseFilledIcon';

export default {
  name: 'SearchField',
  props: ['dataPlaceholder'],
  mixins: [Localization],
  components: {
    SearchIcon,
    CloseFilledIcon,
  },
  emits: {
    update: null,
  },
  data() {
    return {
      removeLabel: this.dataPlaceholder,
      focused: false,
      searchValue: '',
    };
  },
  computed: {
    ...mapGetters([
      'getSearchInputFocused',
      'translations',
    ]),
    placeholder() {
      if (typeof this.dataPlaceholder === 'boolean' && this.dataPlaceholder === false) {
        return '';
      }
      return this.dataPlaceholder || this.translations?.inputSearchPlaceholder;
    },
  },
  methods: {
    onRemove() {
      this.searchValue = '';
      this.emitVal();
    },
    emitVal() {
      this.$emit('update', this.searchValue);
    },
  },
};
</script>

<style scoped lang="scss">
.search {
    position: relative;

    &.focused{
        & .search--icon{
          color: $main-blue;
        }
    }
    &-line{
      width: calc(100% + 40px);
      position: absolute;
      left: -20px;
      height: 1px;
      background-color: #A8AFBC33;
    }
    &--icon{
      color: $light-grey;
      position: absolute;
      left: 2px;
      top: 0px;
      width: 20px;
      height: 20px;
    }

    &--remove{
      color: $light-grey;
      position: absolute;
      right: 4px;
      top: 0px;
      width: 17px;
      height: 17px;
      cursor: pointer;

      &:hover{
        color: darken($light-grey, 12%);
      }
    }
    & input{
      border: unset;
      font-family: 'Gilroy', sans-serif;
      padding: 2px 4px 18px 36px;
      width: calc(100% - 36px - 4px);
      font-size: 17px;
      font-weight: 400;
      &::placeholder,
      &::-webkit-input-placeholder{
        color: $light-grey;
        line-height: 17px;
        font-weight: 400 !important;
        font-size: 17px;
        transform: translateY(0px);
      }
      &:focus,
      &:focus-visible{
        outline: none;
      }
    }
    &.rtl{
      svg{
        padding: unset;
        right: 18px;
      }

      input{
        padding-right: 44px;
      }
    }
  }
</style>
