import { mapGetters } from 'vuex';
import { isEmpty } from 'lodash';

const mixin = {
  name: 'Navigation',
  computed: {
    ...mapGetters([
      'getConfig',
      'getAvailableSources'
    ]),
  },
  methods: {
    navigateFilterSources() {
      if (this.getAvailableSources.sources !== null && !isEmpty(this.getAvailableSources) && this.getAvailableSources.sources !== undefined) {
        // eslint-disable-next-line
        const reducedSources = this.getAvailableSources.sources.filter((source) => {
          // eslint-disable-next-line
          if(source.multiple){
            return true;
          }
          // filter reducedSources by last-fetch if complete filter out
          if ((source['last-fetch'] && source['last-fetch'].status === 'complete') && !source.multiple) {
            return false;
          }

          if (!this.getConnectedSources?.some(
            (connectedSource) => connectedSource.name === source.name,
          )) {
            return true;
          }
          return false;
        });
        return reducedSources[0];
      }
      return null;
    },
    async goBackTo(){
      let navigationResult;
      if(this.getConfig.skip_terms_conditions && this.getAvailableSources?.sources?.length === 1){
        navigationResult = await this.$router.push({ name: 'Login', query: this.$route.query });
        if(!navigationResult){
          this.$store.commit('SET_SELECTED_SOURCE', this.navigateFilterSources())
          return Promise.resolve(true);
        }
        return;
      }
      if(this.getConfig.skip_terms_conditions){
        navigationResult = await this.$router.push({ name: 'Search', query: this.$route.query });
        if(!navigationResult){
          return Promise.resolve(true);
        }
        return;
      }
      navigationResult = await this.$router.push({ name: 'Terms', query: this.$route.query });
      if(!navigationResult){
        return Promise.resolve(true);
      }
    },
  },
};

export default mixin;
