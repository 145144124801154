<template>
  <div class="warning__section">
    <ExclamationOutlinedIcon />
    <div class="warning__section-title">
      <p>
        <Translate :translate="title" />
      </p>
    </div>
    <div class="warning__section-content">
      <p class="warning__text">
        <Translate :translate="text" />
      </p>
    </div>
  </div>
</template>
<script>
import ExclamationOutlinedIcon from '@/components/icons/ExclamationOutlinedIcon';

export default {
  name: 'ActionRequiredWarning',
  components: {
    ExclamationOutlinedIcon
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    text:{
      type: String,
      required: false,
    }
  },
};
</script>
<style lang="scss" scoped>
.warning {

  &__content {
    padding: 20px;
    overflow-y: auto;
    height: calc(100% - 100px);
    p,
    div,
    a {
      color: $dark-grey;
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
    }
    a {
      text-decoration: underline;
    }
  }
  &__section {
    margin-top: 32px;
    display: flex;
    width: calc(100% - 40px);
    padding: 16px 20px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    border-radius: 8px;
    background: #fff7f0;

    &-title {
      color: $black;
      text-align: center;
      font-size: 15px;
      font-weight: 500;
      line-height: 21px;
      align-items: center;
      & p {
        transform: translateY(2px);
      }
    }
    & svg {
        color: #fe9543;
      }
  }

  &__text {
    color:  #FE9543;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 18.2px */
  }

}
</style>
