import types from './mutationTypes';

export default {
  [types.SET_LOADING_APP](state, value) {
    state.loadingApp = value;
  },
  [types.SET_INIT_DATA](state, value) {
    state.initData = value;
  },
  [types.SET_SOURCES](state, value) {
    state.sources = value;
  },
  [types.SET_CONFIG](state, value) {
    state.config = value;
  },
  [types.SET_SOURCES_TEMP](state, value) {
    state.sources_temp = value;
  },
  [types.SET_ERROR](state, value) {
    state.error = value;
  },
  [types.SET_SELECTED_SOURCE](state, value) {
    state.selectedSource = value;
    localStorage.setItem('selected_source', JSON.stringify(value));
  },
  [types.SET_CONNECTED_SOURCES](state, value) {
    state.connectedSources = [];
    state.connectedSources = value;
  },
  [types.SET_SUGGESTED_SOURCES](state, value) {
    state.suggestedSources = value;
  },
  [types.SET_CONNECT_LOGO_POSITION](state, value) {
    state.connectLogoPosition = value;
  },
  [types.SET_SEARCH_INPUT_FOCUSED](state, value) {
    state.searchInputFocused = value;
  },
  [types.SET_AUTH](state, value) {
    state.authenticated = value;
  },
  [types.SET_LOGIN_CREDENTIALS](state, value) {
    state.loginCredentials = value;
  },
  [types.SET_LOGIN_TAB](state, value) {
    state.onLoginTab = value;
  },
  [types.SET_CLOSE_MODAL_ENABLED](state, value) {
    state.closeModalEnabled = value;
  },
  [types.SET_APP_UUID](state, value) {
    state.config.uuid = value;
  },
  [types.SET_ACCOUNT_ID](state, value) {
    state.accountId = value;
  },
  [types.SET_FETCH_UUID](state, value) {
    state.fetchUUID = value;
  },
  [types.SET_FETCH_STATUS](state, value) {
    state.fetchStatus = value;
  },
  [types.SET_COUNTRY_MODAL](state, value) {
    state.countryModal = value;
  },
  [types.SET_FILTER_COUNTRY_MODAL](state, value) {
    state.filterCountryModal = value;
  },
  [types.SET_COUNTRY_SELECTED](state, value) {
    state.countrySelected = value;
  },
  [types.SET_CODE_MODAL](state, value) {
    state.codeModal = value;
  },
  [types.SET_APPROVAL_MODAL](state, value) {
    state.approvalModal = value;
  },
  [types.SET_APPROVAL_WITH_CODE_MODAL](state, value) {
    state.approvalWithCodeModal = value;
  },
  [types.SET_PASSWORD_CODE_MODAL](state, value) {
    state.passwordModal = value;
  },
  [types.SET_WAITING_FETCH_UUID](state, value) {
    state.waitingFetchUUID = value;
  },
  [types.SET_REFRESHED_UUID](state, value) {
    state.refreshed = value;
  },
  [types.SET_FACEBOOK_MODAL](state, value) {
    state.facebookModal = value;
  },
  [types.SET_LEGAL_INFO_MODAL](state, value) {
    state.termsOfUseModal = value;
  },
  [types.SET_TYPE_OF_TERMS](state, value) {
    state.termsOfUsePartner = value;
  },
  [types.SET_NEXT_DATASOURCE](state, value) {
    state.selectedSource.ID = value;
    state.selectedSource.avatar = state.selectedSource.child_datasources[1].logo;
    setTimeout(() => {
      state.selectedSource.next_datasource_id = null;
    }, 3000);
  },
  [types.SET_DOCUMENT_UPLOAD](state, value) {
    state.document = value;
  },
  [types.SET_LOAD_MORE_MODAL](state, value) {
    state.loadMoreModal = value;
  },
  [types.SET_TAX_MODAL](state, value) {
    state.taxModal = value;
  },
  [types.SET_DATE_MODAL](state, value) {
    state.dateModal = value;
  },
  [types.SET_PROVIDER](state, value) {
    state.selectedProvider = value;
  },
  [types.SET_ALL_PLATFORMS_CONNECTED](state, value) {
    state.allPlatformsConnected = value;
  },
};
