<template>
	<div class="modal__wrapper">
		<div class="modal__section">
      <div class="modal__section-image">
        <CheckmarkLineIcon/>
      </div>
      <h3>
        <Translate :translate="'connectedToAllTitle'" />
      </h3>
      <p>
        <Translate :translate="'connectedToAllText'" />
      </p>
		</div>
    <ConnectedSources class="modal__connected" v-if="getConnectedSources?.length > 0"/>
	</div>
</template>
<script>
import General from "@/mixins/general";
import CheckmarkLineIcon from "@/components/icons/CheckmarkLineIcon";
import ConnectedSources from "@/components/ConnectedSources";
import { mapGetters } from 'vuex';

export default {
	name: 'Modal',
  mixins: [ General ],
	components: {
    CheckmarkLineIcon,
    ConnectedSources
	},
  computed:{
    ...mapGetters(['getConnectedSources'])
  }
};
</script>
<style scoped lang="scss">
.modal {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(8px);
  }
  &__section {
    background-color: $white;
    margin: 0 20px;
    width: calc(100% - 40px);
    text-align: center;
    gap: 0;
    padding: 20px;
    &-image{
      width: 60px;
      height: 60px;
      border-radius: 999px;
      background-color: rgba(143, 181, 113, 0.20);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;
    }
    h3 {
      color: $black;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%; /* 25.2px */
      margin-bottom: 8px;
    }

    p {
      color: $dark-grey;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 24px */
    }
  }
  &__connected{
    position: absolute;
    bottom: -20px;
    width: 100%;
  }
}
</style>
