<template>
  <svg width="37" height="38" viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_7232_2071)">
  <path d="M15.4231 23.8801L29.5646 9.73706L31.7416 11.9124L15.4231 28.2309L5.63232 18.4401L7.80771 16.2648L15.4231 23.8801Z" fill="#8FB571"/>
  </g>
  <defs>
  <clipPath id="clip0_7232_2071">
  <rect width="36.9231" height="36.9231" fill="currentColor" transform="translate(0.0385742 0.538574)"/>
  </clipPath>
  </defs>
  </svg>
</template>
