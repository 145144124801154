<template>
  <div class="page page--code" :class="{ 'page--remove': removeModal }">
    <Header
      :title="'Rollee Ride'"
      :hasBack="false"
      :hasClose="true"
      @goBack="onBackToLogin" />
    <div class="page__content">
      <div class="login__source horizontal">
        <div class="login__source-icon">
          <img
            v-if="getAvatar(getSelectedSource)"
            ref="sourceLogo"
            :src="getAvatar(getSelectedSource)" />
          <div v-else class="page__list-default" />
        </div>
        <div :dir="textDirection" class="login__source-title">
          <h2>
            <Translate :translate="'logInTo'" />
            {{ dataSourceName }}
          </h2>
        </div>

      </div>
      <div class="input__wrapper input__wrapper--has-icon-right">
      <input
        :dir="textDirection"
        :type="passwordShow ? 'text' : 'password'"
        class="login__form-password-input"
        v-model="credentials.code"
        @keydown.enter.prevent
        data-gramm="false"
        autocomplete="new-password"
        :placeholder="passwordPlaceholder"
        />
      <VisibleIcon
        class="input__icon"
        :class="{ 'input--disabled': credentials.code?.length === 0 }"
        @click="passwordShow = !passwordShow"
      />
    </div>
      <div class="page__actions">
        <button
          @click="submit"
          :disabled="isDisabled"
          class="button button--primary button--block"
          :class="{ 'button--loading': getFetchUUID }"
        >
          <Translate :translate="'continue'" />
        </button>
      </div>
      <p class="login__forgot">
        <a @click="forgotCredentials()" v-if="getSelectedSource['forgot-password-url']">
          <Translate :translate="'forgotPassword'" />
        </a>
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import Header from '@/components/Header';
import Localization from '@/mixins/localization';
import analytics from '@/api/analytics';
import VisibleIcon from '@/components/icons/VisibleIcon'
export default {
  name: 'CodeModal',
  components: {
    Header,
    VisibleIcon
  },
  data() {
    return {
      disabled: false,
      credentials: {
        code: undefined,
      },
      removeModal: false,
      resendDisabled: false,
      passwordShow: false
    };
  },
  mixins: [Localization],
  mounted() {
    analytics.dispatch({
      action: 'getSessionDuration',
      fromScreen: this.$route.name,
      toScreen: this.getFetchStatus['otp-status'],
    });
  },
  computed: {
    ...mapGetters([
      'getFetchUUID',
      'translations',
      'getFetchStatus',
      'getError',
      'getSelectedSource',
      'getLoginCredentials',
      'getCountries',
    ]),
    isDisabled() {
      // eslint-disable-next-line
      return this.credentials?.code?.length ? false : true;
    },
    passwordPlaceholder() {
      return this.$store.state.translations.password;
    },
    dataSourceName() {
      if (this.getSelectedSource?.child_datasources) {
        if (this.getSelectedSource.next_datasource_id) {
          return this.getSelectedSource?.child_datasources[0].title;
        }
        return this.getSelectedSource.child_datasources[1].title;
      }
      return this.getSelectedSource?.name;
    },
  },
  methods: {
    submit() {
      this.disabled = true;
      // this.credentials.source = this.getSelectedSource.ID;
      this.$store.dispatch('sendConfirmationCode', this.credentials).then(() => {
        setTimeout(() => {
          this.closeModal();
          this.$emit('startLoop', true);
        }, 500);
      }).catch(() => {
        this.closeModal();
        this.$router.push({ name: 'Login', query: this.$route.query });
      });
    },
    closeModal() {
      this.credentials = {
        password: undefined,
        source: undefined
      }
      this.$store.commit('SET_ERROR', null);
      this.$store.commit('SET_PASSWORD_CODE_MODAL', false);
    },
    onBackToLogin() {
      this.closeModal();
      this.$router.push({ name: 'Login' });
    },
    forgotCredentials() {
      // open new window
      window.open(this.getSelectedSource['forgot-password-url'], '_blank');
      window?.analytics.track('forgot_pass_login');
    },
    getDialCode() {
      const [code, phone] = this.getLoginCredentials.username.split('-');
      const dialCode = this.getCountries.find((country) => country.code === code);
      return `${dialCode.dial_code} ${phone}`;
    },
    isError(res){
      if(res?.response.data['error-std'] === 'otp-timeout') {
        this.closeModal();
        this.$emit('startLoop', true);
        return;
      }
    }
  },
};

</script>
<style lang="scss" scoped>
.code{
  &__source {
    margin: 24px 0 20px 0;
  }
  &__tips {
    color: $dark-grey;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 17.5px;
    text-align: start;
    margin-bottom: 12px;
    transform: translateY(-8px);
  }
}
.page {
  &__actions {
    button:first-of-type {
      margin-bottom: 20px;
    }
    &-info {
      margin-top: 6px;
      span {
        color: $dark-grey;
        text-align: center;
        font-size: 11px;
        font-style: normal;
        font-weight: 500;
        line-height: 17.5px
      }
    }
  }
}
.input__wrapper {
  margin-top: 22px;
  min-height: 32px;
  textarea {
    letter-spacing: 4px;
  }
}
</style>
